rodijco.controller 'CustomersUpsertController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, location, httpService) ->

    scope.message = null
    scope.formData = {}
    scope.levelOptions =
      [{
        id: '1',
        name: 'Customer'},
      {
        id: '2',
        name: 'Customer with login'},
      {
        id: '3',
        name: 'Employee'},
      {
        id: '4',
        name: 'Admin'
      }]

    id = routeParams.id

    edit = false
    edit = true if routeParams.id != 'new'

    if edit
      httpService.get "customer/#{id}", (response) ->
        scope.formData = response
        scope.formData.level = scope.levelOptions[response.level - 1].id
    else
      scope.formData.level = scope.levelOptions[0].id

    scope.upsert = (data) ->
      data.id = id
      httpService.post 'customer/upsert', data, (response) ->
        if response.success
          scope.formData = {}
          Materialize.toast 'Klant opgeslagen', DIALOG_DURATION
          location.path('/customers')
        else
          scope.message = response.data?.message

]
